import { Button } from '@cooltra/ui';
import {
  Contract,
  useCancelContractMutation,
} from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useNavigate } from 'react-router-dom';
import { MdInfo } from 'react-icons/md';

import { useNotification } from '~/hooks/useNotification';
import { ConfirmModal } from '~/common';
import { useContractFlags } from '~/hooks';

import messages from './messages';

export type CancelContractButtonProps = {
  contract: Contract;
};

export const CancelContractButton = ({
  contract: { status, insurance, contractId },
}: CancelContractButtonProps) => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const { hasPermission } = useAuthClaimsQuery();
  const navigate = useNavigate();
  const { mutateAsync, isPending } = useCancelContractMutation(contractId);

  const {
    hasDepositBeenWithheld,
    hasPaidTicket,
    hasAwaitingPaymentTicket,
    isSignedOrPendingSignature,
    hasDepositBeenReleasedAutomatically,
  } = useContractFlags();

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(
          formatMessage(messages.successfulCancelConfirmation)
        );
        navigate(`/contracts/short-term/${contractId}/overview`);
      })
      .catch(() => addErrorNotification());

  const handleOpen = () => {
    if (hasDepositBeenWithheld && !hasDepositBeenReleasedAutomatically) {
      addErrorNotification(
        formatMessage(messages.returnDepositBeforeCancelingContract)
      );
      return;
    }
    if (hasAwaitingPaymentTicket) {
      addErrorNotification(
        formatMessage(
          messages.cancelAwaitingPaymentsBeforeMarkingContractAsNoShow
        )
      );
      return;
    }

    if (hasPaidTicket) {
      addErrorNotification(formatMessage(messages.paidPayments));
      return;
    }

    toggleOn();
  };

  if (status !== 'BOOKED' || !hasPermission('cancel:station_based_contracts')) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        className="max-w-md min-w-md"
        dataTestId="CANCEL_CONTRACT_CONFIRM_MODAL"
        isOpen={isOpen}
        title=""
        content={
          <>
            <p className="text-neutral-600 text-sm mb-8">
              <FormattedMessage {...messages.confirmCancel} />
            </p>
            {insurance === 'BASIC' && (
              <div className="flex bg-warning-100 p-2 mb-8">
                <MdInfo className="h-4 w-4 shrink-0 text-warning-600 self-center" />
                <p className="text-sm text-warning-600">
                  <FormattedMessage {...messages.basicInsuranceWarning} />
                </p>
              </div>
            )}
          </>
        }
        onClose={toggleOff}
        onConfirm={handleConfirm}
        loading={isPending}
      />
      <Button
        variant="neutral"
        onClick={handleOpen}
        disabled={isSignedOrPendingSignature}
      >
        <FormattedMessage {...messages.cancel} />
      </Button>
    </>
  );
};
