import { Navigate } from 'react-router-dom';
import { SpinnerScreen } from '@cooltra/ui';
import { useEffect } from 'react';
import { useContractMutation } from '@cooltra/station-based-api';

import { ErrorPage } from '~/common';

export const CreateContract = () => {
  const { mutate, data, error } = useContractMutation();

  useEffect(() => {
    mutate();
  }, [mutate]);

  if (error) {
    return <ErrorPage />;
  }

  if (!data?.contractId) {
    return <SpinnerScreen />;
  }

  return (
    <Navigate replace to={`/contracts/short-term/${data.contractId}/details`} />
  );
};
