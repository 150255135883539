import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import {
  useCreateContractEtendoMutation,
  Contract,
  ContractPayment,
} from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';

import { ConfirmModal, ContractPendingSignAlert } from '~/common';
import { useNotification } from '~/hooks';

import { ContractDeposit } from '../ContractDeposit/ContractDeposit/ContractDeposit';
import { ContractPendingPayments } from '../ContractPendingPayments/ContractPendingPayments';
import { ContractPaidOrRefundedPayments } from '../ContractPaidOrRefundedPayments/ContractPaidOrRefundedPayments';
import { StripePaymentQRProvider } from '../StripePaymentQRProvider/StripePaymentQRProvider';

import messages from './messages';

export type ContractPaymentsProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const ContractPayments = ({
  contract,
  payments,
}: ContractPaymentsProps) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { contractId, status, type, isServicePointRolledOut } = contract;

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { isPending, mutateAsync } =
    useCreateContractEtendoMutation(contractId);

  const validStatusForCreatingOnEtendo = status === 'DRAFT';

  const handleOnConfirm = () =>
    mutateAsync()
      .then(() => {
        addSuccessNotification(formatMessage(messages.success));
        navigate(`/contracts/short-term/${contractId}/overview`);
      })
      .catch(() => {
        addErrorNotification();
        toggleOff();
      });

  const showEtendoButton =
    (type === 'RIDERLY' ||
      type === 'BIKES_BOOKING' ||
      type === 'MANUAL_RESERVATION' ||
      type === 'WALK_BY') &&
    !isServicePointRolledOut;

  return (
    <StripePaymentQRProvider contractId={contractId}>
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <ContractPendingSignAlert />
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-xl text-neutral-700">
            <FormattedMessage {...messages.payments} />
          </h1>
          {showEtendoButton && (
            <>
              <Button
                disabled={!validStatusForCreatingOnEtendo}
                emphasis="high"
                onClick={toggleOn}
              >
                <FormattedMessage {...messages.save} />
              </Button>
              <ConfirmModal
                isOpen={isOpen}
                title={formatMessage(messages.modalTitle)}
                content={formatMessage(messages.modalContent)}
                onClose={toggleOff}
                onConfirm={handleOnConfirm}
                loading={isPending}
              />
            </>
          )}
        </div>
        <ContractDeposit contract={contract} />
        <ContractPendingPayments
          contract={contract}
          contratPaymentTypes={payments}
        />
        <ContractPaidOrRefundedPayments
          contract={contract}
          contratPaymentTypes={payments}
        />
      </div>
    </StripePaymentQRProvider>
  );
};
