import {
  ContractServiceType,
  ContractStatus,
  DailyContractsListItem,
} from '@cooltra/station-based-api';
import { RouterBaseLink } from '@cooltra/navigation';
import { classNames } from '@cooltra/utils';
import { MdAirportShuttle } from 'react-icons/md';

import { CustomIcon } from '~/common';
import { isCustomDelivery, isCustomReturn } from '~/utils/accessories';

export type SmallContractCardLinkProps = Pick<
  DailyContractsListItem,
  'serviceType' | 'status' | 'contractId' | 'accessories' | 'hasClickAndRide'
> & {
  children: React.ReactNode;
};

const contractCardColors: Record<ContractStatus, string[]> = {
  NEW: ['border-neutral-500', 'bg-neutral-50', 'text-neutral-800'],
  DRAFT: ['border-neutral-500', 'bg-neutral-50', 'text-neutral-800'],
  ACTIVE: ['border-success-400', 'bg-success-50', 'text-success-800'],
  BOOKED: ['border-warning-500', 'bg-warning-50', 'text-warning-800'],
  CANCELED: ['border-danger-500', 'bg-danger-50', 'text-danger-800'],
  NO_SHOW: ['border-danger-500', 'bg-danger-50', 'text-danger-800'],
  CLOSED: ['border-neutral-800', 'bg-neutral-50', 'text-neutral-800'],
};

const contractCardBarColors: Record<ContractStatus, string> = {
  NEW: 'bg-neutral-500',
  DRAFT: 'bg-neutral-500',
  ACTIVE: 'bg-success-400',
  BOOKED: 'bg-warning-500',
  CANCELED: 'bg-danger-500',
  NO_SHOW: 'bg-danger-500',
  CLOSED: 'bg-neutral-800',
};

const contractCardBorderSides: Record<ContractServiceType, string> = {
  PICK_UP: 'border-l-[6px] border-t border-r border-b',
  DROP_OFF: 'border-r-[6px] border-t border-b border-l ',
};

const SmallContractCardBar = ({
  status,
  serviceType,
}: {
  status: ContractStatus;
  serviceType: ContractServiceType;
}) => (
  <span
    className={classNames(
      'absolute w-1.5 h-full',
      serviceType === 'PICK_UP' ? 'left-0' : 'right-0',
      contractCardBarColors[status]
    )}
  />
);

export const SmallContractCard = ({
  serviceType,
  status,
  children,
  contractId,
  accessories,
  hasClickAndRide,
}: SmallContractCardLinkProps) => (
  <RouterBaseLink
    to={`/contracts/short-term/${contractId}/overview`}
    className={classNames(
      'border-solid flex items-center rounded px-2.5 h-8 w-full justify-start relative border tabular-nums',
      ...contractCardColors[status],
      contractCardBorderSides[serviceType]
    )}
  >
    <SmallContractCardBar status={status} serviceType={serviceType} />
    {children}
    <span className="ml-auto flex gap-0.5">
      {hasClickAndRide && (
        <span data-testid="CLICK_AND_RIDE">
          <CustomIcon.ClickAndRide className="text-neutral-500 text-sm" />
        </span>
      )}
      {accessories.find(({ name }) => isCustomReturn(name)) &&
        serviceType === 'DROP_OFF' && (
          <span data-testid="SCOOTER_RETURN_ICON">
            <MdAirportShuttle className="text-base text-neutral-500" />
          </span>
        )}
      {accessories.find(({ name }) => isCustomDelivery(name)) &&
        serviceType === 'PICK_UP' && (
          <span className="-scale-x-100" data-testid="SCOOTER_DELIVERY_ICON">
            <MdAirportShuttle className="text-base text-neutral-500" />
          </span>
        )}
    </span>
  </RouterBaseLink>
);
